<template>
  <div>
    <div>
      <GChart
        v-if="chartData.length > 0"
        style="height: 400px"
        type="ColumnChart"
        :data="chartData"
        :options="chartOptions"
      />
    </div>

    <v-simple-table class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              <v-icon
                color="primary"
                small
                class="cursor-pointer mr-1"
                @click="getList"
                >mdi-refresh</v-icon
              >
              {{ $t("labels.time") }}
            </th>
            <th role="columnheader" class="text-center" style="width: 100px">
              {{ $t("labels.source_code") }}
              <!-- <select-multiple
                v-model="filters.id_sources"
                :options="sourceOptions"
                :label="$t('labels.source_code')"
              ></select-multiple> -->
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.timekeeping") }}:
              <span class="error--text">
                {{ formatNumber(sumAll["count_timekeeping_employee"]) }}
              </span>
              <br />
              <small class="error--text">
                E = {{ formatNumber(sumAll["count_performance_employee"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.order_status_20") }}:
              <span class="error--text">
                {{ formatNumber(sumAll["thieu_hang"]) }}
              </span>
              <br />
              <small class="error--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["thieu_hang_item"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.order_status_35_1") }}:
              <span class="error--text">
                {{ formatNumber(sumAll["cho_ma_van_chuyen"]) }}
              </span>
              <br />
              <small class="error--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["cho_ma_van_chuyen_item"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.missing_stamp") }}:
              <span class="error--text">
                {{ formatNumber(sumAll["thieu_tem"]) }}
              </span>
              <br />
              <small class="error--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["thieu_tem_item"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.order_status_40_1") }}:
              <span class="warning--text">
                {{ formatNumber(sumAll["tiep_nhan"]) }}
              </span>
              <br />
              <small class="warning--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["tiep_nhan_item"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.order_status_60_1") }}
              :
              <span class="primary--text">
                {{ formatNumber(sumAll["dang_xu_ly"]) }}
              </span>
              <br />
              <small class="primary--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["dang_xu_ly_item"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.uid_status_4") }}:
              <span class="primary--text">
                {{ formatNumber(sumAll["pickup"]) }}
              </span>
              <br />
              <small class="primary--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["pickup_item"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.pack_1") }}:
              <span class="primary--text">
                {{ formatNumber(sumAll["dong_goi"]) }}
              </span>
              <br />
              <small class="primary--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["dong_goi_item"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.order_status_200_1") }}:
              <span class="success--text">
                {{ formatNumber(sumAll["ban_giao"]) }}
              </span>
              <br />
              <small class="success--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["ban_giao_item"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.wait_return") }}:
              <span class="warning--text">
                {{ formatNumber(sumAll["cho_hoan"]) }}
              </span>
              <br />
              <small class="warning--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["cho_hoan_item"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.order_status_220_1") }}:
              <span class="warning--text">
                {{ formatNumber(sumAll["nhan_hoan"]) }}
              </span>
              <br />
              <small class="warning--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["nhan_hoan_item"]) }}
              </small>
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.order_status_240_1") }}:
              <span class="success--text">
                {{ formatNumber(sumAll["nhap_hoan"]) }}
              </span>
              <br />
              <small class="success--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(sumAll["nhap_hoan_item"]) }}
              </small>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in items"
            :key="`s_${key}`"
            class="text-center tr-h-36px"
          >
            <td>{{ item.date }}</td>
            <td>{{ item.source_code }}</td>
            <td>
              {{ item.count_timekeeping_employee }} <br />
              E = {{ item.count_performance_employee }}
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'thieu_hang')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.thieu_hang) }} <br />
              <small class="error--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(item.thieu_hang_item) }}
              </small>
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'cho_ma_van_chuyen')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.cho_ma_van_chuyen) }} <br />
              <small class="error--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(item.cho_ma_van_chuyen_item) }}
              </small>
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'thieu_tem')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.thieu_tem) }} <br />
              <small class="error--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(item.thieu_tem_item) }}
              </small>
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'tiep_nhan')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.tiep_nhan) }} <br />
              <small class="warning--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(item.tiep_nhan_item) }}
              </small>
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'dang_xu_ly')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.dang_xu_ly) }} <br />
              <small class="primary--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(item.dang_xu_ly_item) }}
              </small>
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'pickup')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.pickup) }} <br />
              <small class="primary--text">
                {{ $t("labels.item") }} = {{ formatNumber(item.pickup_item) }}
              </small>
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'dong_goi')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.dong_goi) }} <br />
              <small class="primary--text">
                {{ $t("labels.item") }} = {{ formatNumber(item.dong_goi_item) }}
              </small>
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'ban_giao')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.ban_giao) }} <br />
              <small class="success--text">
                {{ $t("labels.item") }} = {{ formatNumber(item.ban_giao_item) }}
              </small>
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'cho_hoan')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.cho_hoan) }}
              <small class="warning--text">
                <br />
                {{ $t("labels.item") }} = {{ formatNumber(item.cho_hoan_item) }}
              </small>
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'nhan_hoan')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.nhan_hoan) }}
              <small class="warning--text">
                <br />
                {{ $t("labels.item") }} =
                {{ formatNumber(item.nhan_hoan_item) }}
              </small>
            </td>
            <td
              @click="showDetail(item.id_warehouse, 9999, 'nhap_hoan')"
              class="cursor-pointer"
            >
              {{ formatNumber(item.nhap_hoan) }} <br />
              <small class="success--text">
                {{ $t("labels.item") }} =
                {{ formatNumber(item.nhap_hoan_item) }}
              </small>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="detailDialog" max-width="100vw">
      <StatisticalDetail
        v-if="detailDialog"
        :id-pos="selected.id_pos"
        :id-warehouse="selected.id_warehouse"
        :id-delivery-company="selected.id_delivery_company"
        :state="selected.state"
        :date="filters.date"
        :id-orders="selected.id_orders"
        @cancel="hideDetail"
        :type="tab"
      />
    </v-dialog>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";
import { httpClient } from "@/libs/http";
import { debounce, formatDateTime, formatNumber } from "@/libs/helpers";
import moment from "moment/moment";
import { ORDER_STATUS } from "@/libs/const";

export default {
  components: {
    StatisticalDetail: () => import("@/components/dashboard/StatisticalDetail"),
    GChart,
  },
  name: "DashboardLive",
  props: {
    tab: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    items: [],
    outOfStockIds: [],
    filters: {
      date: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      id_sources: [],
    },
    isLoading: false,
    detailDialog: false,
    checkOrderReturnDialog: false,
    selected: {},
    countByActionItem: [],
    countByActionOrder: [],
    chartColumns: [...ORDER_STATUS],
  }),
  computed: {
    sourceOptions() {
      const { poss, warehouses } = window.me;
      if (this.tab === "pos") {
        return [...poss].map((p) => ({
          value: p.id,
          text: p.code || "",
        }));
      } else {
        return [...warehouses].map((w) => ({
          value: w.id,
          text: w.code_name || "",
        }));
      }
    },
    chartOptions() {
      const options = {
        chart: {
          title: "",
        },
        legend: { position: "none" },
        interpolateNulls: true,
      };

      let series = {};

      return { ...options, series };
    },
    chartData() {
      const items = [];
      const titles = [
        this.$t("labels.status"),
        this.$t("labels.order_1"),
        { role: "style" },
      ];
      items.push(titles);

      const values = [];
      this.chartColumns.forEach((d) => {
        values.push([d.title, this.sumAll[d.key], d.color]);
      });

      items.push(...values);
      return items;
    },
    sumAll() {
      const sum = {
        count_timekeeping_employee: 0,
        count_performance_employee: 0,

        cho_ma_van_chuyen: 0,
        thieu_hang: 0,
        thieu_tem: 0,
        tiep_nhan: 0,
        cho_hoan: 0,
        nhan_hoan: 0,
        dang_xu_ly: 0,
        pickup: 0,
        dong_goi: 0,
        ban_giao: 0,
        nhap_hoan: 0,

        cho_ma_van_chuyen_item: 0,
        thieu_hang_item: 0,
        thieu_tem_item: 0,
        tiep_nhan_item: 0,
        cho_hoan_item: 0,
        nhan_hoan_item: 0,
        dang_xu_ly_item: 0,
        pickup_item: 0,
        dong_goi_item: 0,
        ban_giao_item: 0,
        nhap_hoan_item: 0,
      };
      if (!this.items || this.items.length === 0) {
        return sum;
      }
      this.items.forEach((item) => {
        sum.count_timekeeping_employee += +item.count_timekeeping_employee || 0;
        sum.count_performance_employee += +item.count_performance_employee || 0;

        sum.cho_ma_van_chuyen += +item.cho_ma_van_chuyen || 0;
        sum.thieu_hang += +item.thieu_hang || 0;
        sum.thieu_tem += +item.thieu_tem || 0;
        sum.tiep_nhan += +item.tiep_nhan || 0;
        sum.cho_hoan += +item.cho_hoan || 0;
        sum.nhan_hoan += +item.nhan_hoan || 0;
        sum.dang_xu_ly += +item.dang_xu_ly || 0;
        sum.pickup += +item.pickup || 0;
        sum.dong_goi += +item.dong_goi || 0;
        sum.ban_giao += +item.ban_giao || 0;
        sum.nhap_hoan += +item.nhap_hoan || 0;

        sum.cho_ma_van_chuyen_item += +item.cho_ma_van_chuyen_item || 0;
        sum.thieu_hang_item += +item.thieu_hang_item || 0;
        sum.thieu_tem_item += +item.thieu_tem_item || 0;
        sum.tiep_nhan_item += +item.tiep_nhan_item || 0;
        sum.cho_hoan_item += +item.cho_hoan_item || 0;
        sum.nhan_hoan_item += +item.nhan_hoan_item || 0;
        sum.dang_xu_ly_item += +item.dang_xu_ly_item || 0;
        sum.pickup_item += +item.pickup_item || 0;
        sum.dong_goi_item += +item.dong_goi_item || 0;
        sum.ban_giao_item += +item.ban_giao_item || 0;
        sum.nhap_hoan_item += +item.nhap_hoan_item || 0;
      });
      return sum;
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    formatDateTime,
    formatNumber,
    defaultToday() {
      return [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
    },
    setViewOption(mode) {
      this.viewOption = mode;
    },
    getList: debounce(function () {
      httpClient
        .post("/administration/v1/report-live", {
          ...this.filters,
          type: this.tab,
        })
        .then(({ data }) => {
          this.items = [...data.rows];
        });
    }, 1000),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showDetail(id_warehouse, id_delivery_company, state, id_orders) {
      this.selected = {
        id_warehouse,
        id_delivery_company: id_delivery_company || null,
        state,
        id_orders,
      };
      this.detailDialog = true;
    },
    hideDetail() {
      this.selected = {};
      this.detailDialog = false;
    },
    showCheckOrderReturnDialog() {
      this.checkOrderReturnDialog = true;
    },
    hideCheckOrderReturnDialog() {
      this.checkOrderReturnDialog = false;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
